<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large>
          mdi-dolly
        </v-icon>
        อุปกรณ์ที่ลูกค้าใช้งาน
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customerInventories"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomerInventories"
          class="elevation-1"
        >
          <!-- <template v-slot:[`item.address`]="{ item }">
            <span>
              {{
                `${item.address} ${
                  item.subdistrict.district.province.id == 1 ? "" : "ต."
                }${item.subdistrict.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "อ."
                }${item.subdistrict.district.name_in_thai} ${
                  item.subdistrict.district.province.id == 1 ? "" : "จังหวัด"
                }${item.subdistrict.district.province.name_in_thai} ${
                  item.subdistrict.zip_code
                }`
              }}
            </span>
          </template> -->
          <template v-slot:[`item.costPrice`]="{ item }">
            <span>
              {{
                item.costPrice !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.costPrice)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.customer.id + '/show'"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
              Detail
            </v-btn>
            <!-- <v-btn
              small
              color="primary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.id + '/edit'"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn> -->
            <!-- <v-btn
            small
            color="primary"
            class="ma-2"
            outlined
            :to="'/customer/' + item.id + '/delete'"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            Delete
          </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

moment.locale("th");

export default {
  name: "CustomerInventoryList",
  props: {},
  data() {
    return {
      search: "",
      totalCustomerInventories: 0,
      options: {},
      customerInventories: [],
      loading: false,
      headers: [
        {
          text: "ประเภทอุปกรณ์",
          value: "inventoryType.name",
          sortable: false,
          align: "center",
        },
        {
          text: "ยี่ห้อ",
          value: "inventoryBrand.name",
          sortable: false,
          align: "center",
        },
        {
          text: "รุ่น",
          value: "model",
          sortable: false,
          align: "center",
        },
        {
          text: "S/N",
          value: "sn",
          sortable: false,
          align: "center",
        },
        {
          text: "ราคาทุน",
          value: "costPrice",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "description",
          sortable: false,
          align: "left",
        },
        {
          text: "ชื่อลูกค้า",
          value: "customer.name",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer-inventory", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerInventories = response.data.data;
          this.totalCustomerInventories = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
